/* add css module styles here (optional) */

:root {
  --rft-main-color: #00b879;
  --rft-button-color: #00b879;
  --rft-even-row-color: #f3f3f3;
}
._S4rTU {
  overflow-x: auto;
}
._1N4Iz {
  width: 70% !important;
  padding: 7px !important;
  border-radius: 10px !important;
}

._2lyTg {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

._1PR0i {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-radius: 5px 5px 0 0;
  margin: 3px;
}
._1OmBB {
  border-collapse: collapse;
  width: 100%;
  font-size: 0.9em;
  color: #212529;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  text-align: center;
}

._1OmBB thead tr {
  background-color: var(--rft-main-color);
  color: white;
  font-weight: bold;
  text-transform: capitalize;
}

._1OmBB thead th:hover {
  background-color: #00a36d;
  color: white;
  cursor: pointer;
  transition: 0.3s;
}

._1OmBB thead input {
  font-weight: bold;
  border: none;
  border-radius: 5px;
  padding: 4px;
  width: 80%;
  outline-width: 0;
}

._1OmBB th,
._1OmBB td {
  padding: 12px 15px;
}

._1OmBB tbody tr {
  border-bottom: 1px solid #dddddd;
}

._1OmBB tbody tr:nth-of-type(even) {
  background-color: var(--rft-even-row-color);
}

._1OmBB tbody tr:hover {
  transition: 0.3s;
  cursor: pointer;
  color: #009879;
}

._14pe8 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

._14pe8 select {
  padding: 5px 10px;
}

._3cYlG {
  padding: 10px;
  margin: 10px;
}

._3cYlG {
  border: 3px solid var(--rft-button-color);
  background-color: white;
  padding: 30px 0;
  margin: auto 5px;
  display: inline-block;
  padding: 10px 18px;
  color: var(--rft-button-color);
  font-weight: bold;
  cursor: pointer;
  border-radius: 4px;
  min-width: 100px;
  outline-width: 0;
}
._3nrH2 {
  border: 3px solid var(--rft-button-color);
  background-color: white;
  padding: 30px 0;
  margin: auto 5px;
  display: inline-block;
  padding: 10px 18px;
  color: var(--rft-button-color);
  font-weight: bold;
  cursor: pointer;
  border-radius: 4px;
  min-width: 100px;
  outline-width: 0;
}

._3nrH2:hover {
  background-color: var(--rft-button-color);
  transition: 0.3s;
  color: #fff;
}
._3cYlG:hover {
  background-color: var(--rft-button-color);
  transition: 0.3s;
  color: #fff;
}

._3cYlG:active {
  background-color: var(--rft-button-color);
  color: #fff;
}
