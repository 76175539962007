.checkbox{
    width: 24px;
    height: 24px;
    background: transparent;
    border: 2px solid #3a3a3a;
    border-radius: 7px;
    margin: 5px;
    padding: 0;

    .checkbox-selected{
        width: 18px;
        height: 18px;
        background-color: #3a3a3a;
        border-radius: 5px;
        position: relative;
        margin: auto;
    }

    &:hover{
        cursor: pointer;

        & > .checkbox-selected{
            opacity: 0.7;
        }
    }

}
